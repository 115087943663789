@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");
@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
  width: 100vw;
  overflow-x: hidden;
  min-width: 320px;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #000;
}

.selected {
  font-weight: 800;
  transform: scale(1.1);
}

.selectedmobile {
  border-left: 3px solid #ff4d00;
}

.review {
  color: rgba(0,0,0,0.65);
}